import {makeAutoObservable} from "mobx";
import {OKTA_POST, POST} from "../../api/requests/request"

// import {LOGIN_ROUTE} from "../../variable.environment"
// import {REGISTER_ROUTE} from "../../variable.environment";

import GlobalStore from "../global-store/global.store"
import {LOGIN_ROUTE, REGISTER_ROUTE} from "../../api/variable.routingServer";

class AuthStore {

    constructor() {
        makeAutoObservable(this)
    }

    async handleAddCredoLogin(payloadLogin) {
        try {
            console.log(payloadLogin)
            const {data} = await POST( LOGIN_ROUTE, payloadLogin)
            console.log({data})
            const dataToStringLogin = JSON.stringify(data)
            localStorage.setItem('rememberMe', dataToStringLogin);
            await GlobalStore.addResponseToGlobalStore(data)
        } catch (e) {
            console.log(e)
        }
    }

    async handleAddCredoRegister(payloadRegister) {
        try {
            const res = await OKTA_POST(payloadRegister)
            console.log(res)
            const dataToStringRegister = JSON.stringify(res)
            localStorage.setItem('rememberMe', dataToStringRegister);
            await GlobalStore.addResponseToGlobalStore(res)
        } catch (e) {
            console.log(e)
        }
    }

}
export default new AuthStore()