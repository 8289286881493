import './App.css';
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import SignUpPage from "../pages/signUp/signUp.page"
import {UserPage} from "../pages/user/user.page";
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import {useHistory} from "react-router";
import {RegistrationPage} from "../pages/registration/registration.page";

const oktaDomain = process.env.REACT_APP_AUTH_OKTA_URL
const clientId = process.env.REACT_APP_CLIENT_ID

console.log(oktaDomain, clientId)

const oktaAuth = new OktaAuth({
    issuer: oktaDomain,
    clientId: clientId,
    redirectUri: window.location.origin + '/',
});

function App() {
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

const onAuthRequired = ({history}) => {
    history.push('/')
}

  return (
      <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
            <div className="App">
              <Switch>
                <Route exact path="/" component={SignUpPage} />

                {/*  TODO configure the SecureRoute*/}
                {/*<SecureRoute path="/user" component={UserPage} />*/}

                {/*  Attention dev rout /user/dev */}
                  <Route exact path="/user/dev" component={UserPage} />

                  {/*<Route path="/" component={MainPage} />*/}
                  <Route exact path="/user/register" component={RegistrationPage} />

              </Switch>
            </div>
      </Security>
  );
}

const AppWithRouterAccess = () => (
    <Router>
        <App />
    </Router>
);

export default AppWithRouterAccess;
