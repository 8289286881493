import React from "react";
import {DescriptionText, TitleBox, TitleText} from "./Title.styles";

export const Title = ({textTitle, textDescription, width}) => {
    return (
        <TitleBox
            widthPropsTitle={width}
        >
            <TitleText>
                {textTitle}
            </TitleText>
            <DescriptionText>
                {textDescription}
            </DescriptionText>
        </TitleBox>
    )
}