import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {FormBox, useStyles} from "./jobPreferenceForm.styles";
import InputForm from "../../common-Components/Input/input";
import Divider from "@material-ui/core/Divider";
import {Title} from "../../common-Components/Title/Title";

export const JobPreferenceForm = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="max">
                <form className={classes.root} noValidate autoComplete="off">
                    <Title
                        textTitle={'Details'}
                        textDescription={'This information will be displayed publicly so be careful what you share.'}
                    />
                    <Divider className={classes.divider} />
                    <FormBox>
                        <Title
                            textTitle={'Job Title'}
                            textDescription={'Job titles must describe one position.'}
                        />
                        <InputForm widthInput={'500px'}/>
                    </FormBox>

                </form>
            </Container>
        </React.Fragment>
    );
}