import {makeStyles, styled} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    divider: {
       margin: '20px 0 20px 0',
       width: '100%'
    }
}));

export const FormBox = styled('div')({
    display: "flex",
    width: "50%",
    alignItems: "and"
})
