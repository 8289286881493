import {makeStyles, styled} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
            justifyContent: "center"
        },
    },
    divider: {
        margin: '20px 0 20px 0',
        width: '100%'
    }
}));

export const FormBoxRegister = styled('div')({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
})

export const FormUserDetails = styled('form')({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
})



