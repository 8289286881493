import {styled} from "@material-ui/core/styles";

export const TitleText = styled('div')({
    paddingBottom: '5px',
    fontWeight: '600',
    fontSize: '2rem'
})

export const DescriptionText = styled('div')({
    width: '100%',
    fontSize: '1rem',
    paddingBottom: '10px'
})

export const TitleBox = styled('div')({
    width: ({widthPropsTitle}) => widthPropsTitle ? widthPropsTitle : '100%',
})