import { styled } from '@material-ui/core/styles';

export const LogoBox = styled('div')({
    marginLeft: ({marginleftlogo}) =>  marginleftlogo ? marginleftlogo : "25px",
    marginTop: ({margintoplogo}) =>  margintoplogo ? margintoplogo : "25px",
    marginBottom: '20px',
    width: ({widthLogo}) =>  widthLogo ,
    height: ({heightLogo}) =>  heightLogo,
    padding: '5px'
});
export const LogoImg = styled('img')({
    width: ({width}) => width ? width : '100%',
    height: '100%'
});
