import React from "react";
import logo from "../../common/images/increds-logo-transparent.png"
import {LogoBox, LogoImg} from "./logo.styles"

export const Logo = ({width, height, marginLeft, marginTop}) => {

    return (
        <LogoBox
            marginleftlogo={marginLeft}
            margintoplogo={marginTop}
            widthlogo={width}
            heightlogo={height}
        >
            <LogoImg width={width} src={logo} alt="The logo"/>
        </LogoBox>
    )
}