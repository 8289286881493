import React from "react";
import {Title} from "../../../common-Components/Title/Title";
import InputForm from "../../../common-Components/Input/input";

export const InputBlock = ({titleText, labelText}) => {
    return (
        <>
            <Title
                width={'25rem'}
                textDescription={titleText}
            />
            <InputForm
                labelProps={labelText}
                marginLeft={'0'}
                widthInput={'25rem'}
            />
        </>
    )
}