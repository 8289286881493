import {makeStyles} from "@material-ui/core/styles";
import {styled} from "@material-ui/core";

export const useStyles = makeStyles({
    root: {
        width: ({widthInput}) => widthInput ? widthInput : '600px',
    }
});
export const InputBox = styled('div')({
    width: ({widthProps}) => widthProps ? widthProps : "50%",
    marginLeft: ({marginLeftProps}) => marginLeftProps ? marginLeftProps : "50%"
})