import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './signUp.styles';
import {Logo} from "../../Components/Logo/Logo";
import {useOktaAuth} from "@okta/okta-react";
import AuthStore from "../../store/authentication/auth.store"

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
                Increds
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function SignUpPage() {

    const classes = useStyles();
    const { oktaAuth, authState } = useOktaAuth();

    const [email, setEmail] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await AuthStore.handleAddCredoRegister(email)
        }catch (err) {
            console.log(err)
        }
    };


    const handleAddEmail = (e) => {
        e.preventDefault()
        setEmail(e.target.value)
    }

    //TODO add data to global store
    // const handleClickSendEmail = async () => {
    //     try{
    //         await AuthStore.handleAddCredo(email)
    //     }catch (e) {
    //         throw new Error(e)
    //     }
    // }

    console.log({authState})

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Logo
                    width={'47rem'}
                    marginLeft={'0'}
                />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Sign Up
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={ e => handleAddEmail(e)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                        >
                            Create
                        </Button>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
        </Grid>
    );
}