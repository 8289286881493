import React from "react";
import {JobPreferenceForm} from "../../../Components/Forms/Job-preference-form/JobPreferenceForm";

export const menuMap = {
    'Personal & contact details': <JobPreferenceForm />,
    'Qualifications': <div><h1>Qualifications</h1></div>,
    'Employment history': <div><h1>Employment</h1></div>,
    'Registration': <div><h1>Registration</h1></div>,
    'New Hospital application': <div><h1>New Hospital application</h1></div>,
    'Completed applications': <div><h1>Completed applications</h1></div>,
    'Career level': <div><h1>Career level</h1></div>,
    'Received': <div><h1>Received</h1></div>,
    'Requests': <div><h1>Requests</h1></div>,
}