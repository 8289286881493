import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {Title} from "../../common-Components/Title/Title";
import {FormBoxRegister, FormUserDetails, useStyles} from "./registrationForm.styles"
import InputForm from "../../common-Components/Input/input";
import {Logo} from "../../Logo/Logo";
import {InputBlock} from "./Input-block/inputBlock";
import Button from "@material-ui/core/Button";

export const RegistrationForm = () => {
    const classes = useStyles();


    return (
        <React.Fragment>
            <Logo
                width={'20rem'}
                marginTop={'0'}
                marginLeft={'0'}
            />
            <CssBaseline />
            <Container maxWidth="xs">
                <FormUserDetails className={classes.root} noValidate autoComplete="off">
                    <FormBoxRegister>
                        <InputBlock
                            titleText={'Please enter your first name.'}
                            labelText={"First name"}
                        />
                    </FormBoxRegister>
                    <FormBoxRegister>
                        <InputBlock
                            titleText={'Please enter your last name.'}
                            labelText={"Last name"}
                        />
                    </FormBoxRegister>
                    <FormBoxRegister>
                        <InputBlock
                            titleText={'Please enter your organisation.'}
                            labelText={"Organisation"}
                        />
                    </FormBoxRegister>
                    <FormBoxRegister>
                        <InputBlock
                            titleText={'Please enter phone number.'}
                            labelText={"Phone number"}
                        />
                    </FormBoxRegister>

                    <Button
                        fullWidth={'20rem'}
                        variant="contained"
                        color="primary"
                        href="#contained-buttons"
                    >
                        Continue
                    </Button>

                </FormUserDetails>
            </Container>
        </React.Fragment>
    );
}