import React, {useState} from "react";
import MenuBar from "../../Components/Menu-bar/MenuBar";

import {useStyles} from "../../Components/Menu-bar/menuBar.styles";
import {menuMap} from "./menu-interface/menu.interface";

export const UserPage = () => {
    const classes = useStyles();
const [renderComponent, setRenderComponent] = useState('')

    const addBtnOfComponent = (btnClick) => {
        setRenderComponent(btnClick)
    }

    console.log({renderComponent})
    return (
        <div className={classes.root}>
            <MenuBar addToStateRender={addBtnOfComponent}/>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {menuMap[renderComponent]}
            </main>
        </div>
    );
}