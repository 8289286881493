import React from "react";

export const credentials = ['Personal & contact details', 'Qualifications', 'Employment history', 'Registration']
export const career = ['Career level']
export const hospitals = ['New Hospital application', 'Completed applications']
export const references = ['Received', 'Requests']

export const nestMenuMap = {
    Personal: 'Personal & contact details',
    Qualifications: 'Qualifications',
    Employment: 'Employment history',
    Registration: 'Registration',
    NewHospital: 'New Hospital application',
    CompletedApp: 'Completed applications',
    CareerLevel: 'Career level',
    Received: 'Received',
    Requests: 'Requests',
}