import React, {useState} from "react";
import {Logo} from "../../Logo/Logo";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
    AssignmentInd, AssignmentTurnedIn, CheckCircle,
    EnhancedEncryption,
    ExpandLess,
    ExpandMore, FiberNew,
    HowToReg, InsertLink, LocalHospital,
    MenuBook,
    PersonOutline, SaveAlt, Send, Work
} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import {Collapse} from "@material-ui/core";
import {useStylesForNestedList} from "./btnList.styles";
import {career, credentials, hospitals, nestMenuMap, references} from "./listNameBtn";

export const DrawerList = ({handleAddNameBtn}) => {
    const classesForNestedList = useStylesForNestedList()
    const [openSubCredentials, setOpenSubCredentials] = useState(false);
    const [openSubCareer, setOpenSubCareer] = useState(false);
    const [openSubHospital, setOpenSubHospital] = useState(false);
    const [openSubReferences, setOpenSubReferences] = useState(false);

    const handleClick = () => {
        setOpenSubCredentials(!openSubCredentials);
    };

    const handleClickCareerSub = () => {
        setOpenSubCareer(!openSubCareer);
    };

    const handleClickHospitalSub = () => {
        setOpenSubHospital(!openSubHospital);
    };

    const handleClickReferencesSub = () => {
        setOpenSubReferences(!openSubReferences);
    };

    return(
        <div>
            <Logo
                width={'200px'}
                height={'60px'}
                marginLeft={'12px'}
                marginTop={'20px'}
            />
            <Divider />
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classesForNestedList.root}
            >
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        <AssignmentInd />
                    </ListItemIcon>
                    <ListItemText primary="Credentials" />
                    {openSubCredentials ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSubCredentials} timeout="auto" unmountOnExit>

                    {credentials.map((text) => (
                        <ListItem
                            className={classesForNestedList.nested}
                            button
                            key={text}
                            onClick={() =>handleAddNameBtn(text)}
                        >
                            <ListItemIcon>
                                {(text === nestMenuMap.Personal) && <PersonOutline />}
                                {(text === nestMenuMap.Qualifications) && <EnhancedEncryption />}
                                {(text === nestMenuMap.Employment) && <MenuBook />}
                                {(text === nestMenuMap.Registration) && <HowToReg />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </Collapse>
            </List>
            <Divider />
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classesForNestedList.root}
            >
                <ListItem button onClick={handleClickCareerSub}>
                    <ListItemIcon>
                        <Work />
                    </ListItemIcon>
                    <ListItemText primary="Career" />
                    {openSubCareer ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSubCareer} timeout="auto" unmountOnExit>

                    {career.map((text) => (
                        <ListItem
                            className={classesForNestedList.nested}
                            button
                            key={text}
                            onClick={() =>handleAddNameBtn(text)}
                        >
                            <ListItemIcon>
                                {(text === nestMenuMap.NewHospital) && <AssignmentTurnedIn />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}

                </Collapse>
            </List>
            <Divider />
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classesForNestedList.root}
            >
                <ListItem button onClick={handleClickHospitalSub}>
                    <ListItemIcon>
                        <LocalHospital />
                    </ListItemIcon>
                    <ListItemText primary="Hospitals" />
                    {openSubHospital ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSubHospital} timeout="auto" unmountOnExit>

                    {hospitals.map((text) => (
                        <ListItem
                            className={classesForNestedList.nested}
                            button
                            key={text}
                            onClick={() =>handleAddNameBtn(text)}
                        >
                            <ListItemIcon>
                                {(text === nestMenuMap.CompletedApp) && <FiberNew />}
                                {(text === nestMenuMap.CareerLevel) && <CheckCircle />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}

                </Collapse>
            </List>
            <Divider />
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classesForNestedList.root}
            >
                <ListItem button onClick={handleClickReferencesSub}>
                    <ListItemIcon>
                        <InsertLink />
                    </ListItemIcon>
                    <ListItemText primary="References" />
                    {openSubReferences ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSubReferences} timeout="auto" unmountOnExit>

                    {references.map((text) => (
                        <ListItem
                            className={classesForNestedList.nested}
                            button
                            key={text}
                            onClick={() =>handleAddNameBtn(text)}
                        >
                            <ListItemIcon>
                                {(text === nestMenuMap.Received) && <Send />}
                                {(text === nestMenuMap.Requests) && <SaveAlt />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}

                </Collapse>
            </List>
            <Divider />
        </div>
    );
}