import axios from "axios";

const serverURL = process.env.REACT_APP_SERVER_URL
const apiLocalToken = process.env.REACT_APP_API_LOCAL_TOKEN
const oktaDomain = process.env.REACT_APP_AUTH_OKTA_URL

export const POST = async (route, payloadRequest, options) => {
    console.log(payloadRequest)

    try{
        const res = await axios.post(`${serverURL}${route}`, payloadRequest, options)
        console.log(res)
        // document.cookie = res.headers.cookie

        return res
    } catch (err) {
        console.log(err)
    }
}

export const GET = async (route, payloadRequest) => {
    try{
        return await axios.get(`${serverURL}${route}`, payloadRequest)
    } catch (err) {
        console.log(err)
    }
}

export const OKTA_POST = async (payloadRequest) => {
    console.log(payloadRequest)

    try{
        const res = await axios.post(`${oktaDomain}`, {
            "profile": {
                "login": payloadRequest,
                "email": payloadRequest,
                "firstName": "expecting...",
                "lastName": "expecting...",
                "userType": "doctor"
            }
        }, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `SSWS ${apiLocalToken}`
            }
        })
        console.log("OKTA_POST", res)
        // document.cookie = res.headers.cookie

        return res
    } catch (err) {
        console.log(err)
    }
}