import {makeAutoObservable} from "mobx";

class GlobalStore {

    //Login/Registration store
    globalStorageForAuth = {};
    //--------------------------

    constructor() {
        makeAutoObservable(this)
    }

    async addResponseToGlobalStore(payloadAuth) {
        try {
            this.globalStorageForAuth = {payloadAuth}
        } catch (err) {
            console.log(err)
        }
    }

}

export default new GlobalStore()