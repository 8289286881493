import React from "react";
import {useStyles} from "../../pages/signUp/signUp.styles";
import {RegistrationForm} from "../../Components/Forms/registration-form/registrationForm";

export const RegistrationPage = () => {

    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <RegistrationForm />
        </div>
    )
}