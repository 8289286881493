import React from 'react';
import TextField from '@material-ui/core/TextField';
import {InputBox, useStyles} from "./input.styles";

export default function InputForm({widthInput, marginLeft, labelProps}) {
    const classes = useStyles({widthInput})
    return (
        <InputBox
            widthProps={widthInput}
            marginLeftProps={marginLeft}
        >
            <TextField className={classes.root} id="outlined-basic" label={labelProps} variant="outlined" />
        </InputBox>
    );
}